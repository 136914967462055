import * as Objects from 'BaxterScript/helper/object/Object';
import { getConfigById } from 'BaxterScript/helper/config/Config';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';

const addToTargeting = (slot, targeting) => {
  slot.targeting = { ...slot.targeting, ...targeting };
};

const initializePageTargeting = (providerSettings, params) => {
  const ranges = globalThis.Baxter.config.app?.ranges;
  const targeting = Objects.parseMap(providerSettings.targeting || [], params, ranges) as TargetingParams;
  // eslint-disable-next-line no-param-reassign
  delete targeting.featureFlags;
  return targeting;
};

const initializeSlotTargeting = (slot, providerSettings, providerConfig, pageId, containerId, slotId, params) => {
  if (providerSettings.targeting) {
    const ranges = globalThis.Baxter.config.app?.ranges;
    const defaultTargetingMap = providerConfig?.targeting || [];
    addToTargeting(slot, Objects.parseMap(defaultTargetingMap, params, ranges));
    const slotTargeting = getConfigById(providerSettings.targeting, pageId, containerId, slotId);
    if (slotTargeting) {
      addToTargeting(slot, Objects.parseMap(slotTargeting.map || [], params, ranges));
    }
    // eslint-disable-next-line no-param-reassign
    delete slot.targeting.featureFlags;
  }
};

export { initializePageTargeting, initializeSlotTargeting };
