import * as State from 'BaxterScript/version/web/core/State';
import { Config } from 'BaxterScript/types/Config';
import { Features } from 'BaxterScript/version/web/config/Features';
import { Debounce } from 'BaxterScript/helper/event/Debounce';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { FindAndSetAndCreateSlotFunction } from 'BaxterScript/types/ContainerType';
import * as Provider from 'BaxterScript/version/web/core/Provider';
import { getConfigById } from 'BaxterScript/helper/config/Config';
import { Slot } from 'BaxterScript/types/Slot';

export const webpackExclude = (config: Config): boolean => {
  const settings = config.slots?.settings?.breakpointRefresh;
  return !(
    (settings?._ && Object.values(settings._).some((item) => !!item?.enabled)) ||
    (settings && Object.values(settings).some((item) => !!item?.enabled))
  );
};

export const id = Features.BREAKPOINT_REFRESH;

const getSettings = (slot: Slot) => {
  const settings = globalThis.Baxter.config.slots.settings?.breakpointRefresh;
  return getConfigById(settings, slot.pageId, slot.containerIdWithWildcard ?? slot.containerId, slot.id);
};

const isEnabledForSlot = (slot: Slot) => {
  const settings = getSettings(slot);
  return settings && settings.enabled;
};

const refreshSlots = async (findAndSetAndCreateSlot: FindAndSetAndCreateSlotFunction) => {
  if (State.getBreakpoints().length && State.setBreakpointAndDeviceSize()) {
    const slotsToLoad = Object.values(State.getContainers())
      .filter((container) => container.state.slot && isEnabledForSlot(container.state.slot))
      .map((container) => ({
        container,
        loadable: findAndSetAndCreateSlot(id, container.state.slot!.pageId, container, container.state.slot!.params),
      }))
      .filter((containerWithLoadable) => containerWithLoadable.loadable)
      .map((containerWithLoadable) => containerWithLoadable.container.state.slot!);
    await Provider.load(id, slotsToLoad);
  }
};

const bootstrap = (findAndSetAndCreateSlot: FindAndSetAndCreateSlotFunction) => {
  window.addEventListener(
    'resize',
    Debounce(async () => {
      try {
        console.info('[SLOTS][BREAKPOINTREFRESH][RESIZE]');
        await refreshSlots(findAndSetAndCreateSlot);
      } catch (e) {
        console.error('[SLOTS][BREAKPOINTREFRESH][RESIZE]', e);
        newRelicMetrics.reportError(NewRelicError.BREAKPOINT_REFRESH_HANDLE_RESIZE_ERROR, {
          message: (e as Error).message,
        });
      }
    }, 300)
  );
};

// eslint-disable-next-line import/no-default-export
export default {
  bootstrap,
};
