import { Config } from 'BaxterScript/types/Config';
import * as Provider from 'BaxterScript/version/web/core/Provider';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { GoogleImaConfig, GoogleImaCoreConfig } from 'BaxterScript/types/ProviderSlotConfig/GoogleIma';
import { Features } from 'BaxterScript/version/web/config/Features';
import { AutoplaySlot, Slot } from 'BaxterScript/types/Slot';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { getConfigById } from 'BaxterScript/helper/config/Config';
import * as Observer from 'BaxterScript/helper/browser/Observer';

export const id = Features.AUTOPLAY;
const GOOGLE_IMA_ID = Providers.GOOGLE_IMA;

export const webpackExclude = (config: Config): boolean => {
  const providerSettings = (config.slots?.providerSettings?.[GOOGLE_IMA_ID] ?? {}) as GoogleImaConfig;
  const coreSettings = providerSettings.core;
  return !(
    (coreSettings?._ && Object.values(coreSettings._).some((item) => item?.autoplay)) ||
    (coreSettings && Object.values(coreSettings).some((item) => item?.autoplay))
  );
};

const removeObserver = (slot: AutoplaySlot) => {
  console.info('[SLOTS][AUTOPLAY][REMOVEOBSERVER]');
  Observer.removeObserver(slot.innerHtmlElement, slot[id].state);
};

const removeVisibilityChangeListener = (slot: AutoplaySlot) => {
  if (slot[id].state.visibilityChangeListener) {
    document.removeEventListener('visibilitychange', slot[id].state.visibilityChangeListener);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.visibilityChangeListener = undefined;
  }
};

const autoplay = (autoplaySlot: AutoplaySlot) => {
  Provider.autoplay(autoplaySlot);
};

const setAutoplayVisibilityListener = (autoplaySlot: AutoplaySlot) => {
  if (document.visibilityState === 'visible') {
    autoplay(autoplaySlot);
  } else {
    console.debug('[SLOTS][AUTOPLAY][TRYAUTOPLAY] visibleState != visible');
    newRelicMetrics.reportMetric(NewRelicMetric.AUTOPLAY_ADD_VISIBILITY_CHANGE_LISTENER);
    // eslint-disable-next-line no-param-reassign
    autoplaySlot[id].state.visibilityChangeListener = () => {
      try {
        removeVisibilityChangeListener(autoplaySlot);
        console.debug(`[SLOTS][AUTOPLAY][TRYAUTOPLAY][VISIBILITYCHANGE] ${document.visibilityState}`);
        newRelicMetrics.reportMetric(NewRelicMetric.AUTOPLAY_VISIBILITY_CHANGED, {
          visibility: document.visibilityState,
        });
        autoplay(autoplaySlot);
      } catch (err) {
        console.error('[SLOTS][AUTOPLAY][TRYAUTOPLAY][VISIBILITYCHANGE]', err);
        newRelicMetrics.reportError(NewRelicError.AUTOPLAY_VISIBILITY_CHANGE_ERROR, {
          message: (err as Error).message,
        });
      }
    };
    document.addEventListener('visibilitychange', autoplaySlot[id].state.visibilityChangeListener);
  }
};

const addResizeObserver = (autoplaySlot: AutoplaySlot): void => {
  console.info('[SLOTS][AUTOPLAY][ADDRESIZEOBSERVER]');
  // eslint-disable-next-line no-param-reassign
  autoplaySlot[id].state.resizeObserver = Observer.createResizeObserver(
    autoplaySlot.innerHtmlElement,
    {
      onResize: () => {
        if (!autoplaySlot[id].state.autoplayed) {
          const boundingRect = autoplaySlot.innerHtmlElement.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
          const top = Math.max(boundingRect.top, 0);
          const bottom = Math.min(boundingRect.bottom, viewportHeight);
          if (boundingRect.height > 0 && bottom - top > boundingRect.height / 2) {
            // eslint-disable-next-line no-param-reassign
            autoplaySlot[id].state.autoplayed = true;
            removeObserver(autoplaySlot);
            console.debug('[SLOTS][AUTOPLAY][RESIZEOBSERVER] Provider.autoplay(...)');
            setAutoplayVisibilityListener(autoplaySlot);
          }
        }
      },
    },
    'AUTOPLAY',
    NewRelicError.AUTOPLAY_RESIZE_OBSERVER_ERROR
  );
};

const addIntersectionObserver = (autoplaySlot: AutoplaySlot): void => {
  console.info('[SLOTS][AUTOPLAY][ADDINTERSECTIONOBSERVER]');
  // eslint-disable-next-line no-param-reassign
  autoplaySlot[id].state.intersectionObserver = Observer.createIntersectionObserver(
    autoplaySlot.innerHtmlElement,
    {
      threshold: 0.5,
      onIntersect: () => {
        if (!autoplaySlot[id].state.autoplayed) {
          // eslint-disable-next-line no-param-reassign
          autoplaySlot[id].state.autoplayed = true;
          removeObserver(autoplaySlot);
          console.debug('[SLOTS][AUTOPLAY][INTERSECTIONOBSERVER] Provider.autoplay(...)');
          setAutoplayVisibilityListener(autoplaySlot);
        }
      },
    },
    'AUTOPLAY',
    NewRelicError.AUTOPLAY_INTERSECTION_OBSERVER_ERROR
  );
};

const applyToSlot = (autoplaySlot: AutoplaySlot): void => {
  console.info('[SLOTS][AUTOPLAY][APPLYTOSLOT]', autoplaySlot);
  addIntersectionObserver(autoplaySlot);
  addResizeObserver(autoplaySlot);
};

const apply = (slot: Slot): boolean => {
  const providerSettings = (globalThis.Baxter.config.slots?.providerSettings?.[GOOGLE_IMA_ID] || {}) as GoogleImaConfig;
  const core = (getConfigById(providerSettings.core, slot.pageId, slot.containerId, slot.id) ||
    {}) as GoogleImaCoreConfig;
  // eslint-disable-next-line no-param-reassign
  slot[id] = {
    config: { core },
    state: {},
  };
  if (!slot[id].config.core.autoplay) {
    return false;
  }
  if (slot[id].state.alreadyApplied) {
    return false;
  }
  // eslint-disable-next-line no-param-reassign
  slot[id].state.alreadyApplied = true;
  applyToSlot(slot as AutoplaySlot);
  return true;
};

export const remove = (slot: Slot): void => {
  if (slot[id]?.config?.core?.autoplay) {
    console.info('[SLOTS][AUTOPLAY][REMOVE]', slot);
    removeObserver(slot as AutoplaySlot);
    removeVisibilityChangeListener(slot as AutoplaySlot);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.alreadyApplied = false;
    // eslint-disable-next-line no-param-reassign
    slot[id].state.autoplayed = false;
  }
};

// eslint-disable-next-line import/no-default-export
export default {
  apply,
  remove,
};
